/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCJRmoSBV0nxbUb7esvgCaz1iUsyO7ppag",
  "appId": "1:503228393753:web:94aeb1dd753d71f66c3d88",
  "authDomain": "schooldog-instance-candler.firebaseapp.com",
  "measurementId": "G-W6JC3R91BS",
  "messagingSenderId": "503228393753",
  "project": "schooldog-instance-candler",
  "projectId": "schooldog-instance-candler",
  "showGaBranding": true,
  "storageBucket": "schooldog-instance-candler.appspot.com"
}
